@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap");
.loading {
  padding: 5rem;
  font-family: "Titillium Web", sans-serif;
  font-size: 3rem;
  color: #363b3b;
  text-align: center;
  animation: Pulse 1s infinite; }

.wrap {
  width: 100%;
  max-width: 992px;
  margin: 0 auto; }

.half-wrap {
  max-width: 496px; }

.imageclass {
  width: 100%;
  height: auto; }

@keyframes Pulse {
  0% {
    color: #e2e2e2; }
  50% {
    color: #363b3b; }
  100% {
    color: #e2e2e2; } }
