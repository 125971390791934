@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap");
.interstitial-ga {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.9);
  min-height: 100vh;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: middle;
  width: 100%; }
  .interstitial-ga .interstitial-content {
    max-width: 50%;
    margin: 0 auto;
    font-size: 1.5rem; }
  .interstitial-ga .interstial-links a {
    display: inline-block;
    padding: .5rem 1rem;
    margin: 0 .5rem .5rem 0;
    background: #990000;
    color: #000;
    text-decoration: none;
    border-radius: 5px;
    transition: background .5s;
    color: #fff;
    font-size: 1rem; }
    .interstitial-ga .interstial-links a:nth-child(2) {
      background: #666; }
    .interstitial-ga .interstial-links a:hover {
      background: #330000; }
