@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap");
.interstitial {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: rgba(255, 255, 255, 0.9);
  min-height: 100vh;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: middle;
  width: 100%; }
  @media screen and (min-width: 768px) {
    .interstitial {
      background: rgba(0, 0, 0, 0.9); } }

.interstitial__content {
  padding: 40px;
  max-width: 770px;
  margin: 0 auto;
  font-size: 1.5rem;
  color: black;
  text-align: center;
  position: relative; }
  @media screen and (min-width: 768px) {
    .interstitial__content {
      border-radius: 12px;
      background-color: white; } }

.interstitial__text {
  max-width: 560px;
  padding-bottom: 30px;
  color: #333333;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  text-align: left; }
  @media screen and (min-width: 768px) {
    .interstitial__text {
      text-align: center;
      max-width: 592px; } }

.interstitial__title {
  font-size: 42px;
  color: #1c274b;
  font-family: "Titillium Web", sans-serif;
  font-weight: 700;
  line-height: 1.19;
  letter-spacing: -0.35px;
  text-align: left; }
  @media screen and (min-width: 768px) {
    .interstitial__title {
      text-align: center; } }

.interstitial__links {
  display: flex;
  flex-direction: column; }
  @media screen and (min-width: 768px) {
    .interstitial__links {
      flex-direction: row;
      justify-content: center; } }

.interstial__link {
  font-size: 20px;
  padding: 5px 45px;
  margin: 12px 0;
  max-width: 220px;
  background-color: #ffbc00;
  border: none;
  cursor: pointer;
  border-radius: 50px;
  color: #000;
  text-transform: uppercase;
  font-family: "Titillium Web", sans-serif;
  font-weight: 700;
  transition: all 200ms; }
  @media screen and (min-width: 768px) {
    .interstial__link {
      margin: 0 13px; } }
  .interstial__link:hover, .interstial__link:focus {
    background-color: #1c274b;
    color: #fff;
    cursor: pointer; }
  .interstial__link:nth-child(2) {
    background-color: #1c274b;
    color: #fff; }
    .interstial__link:nth-child(2):hover {
      background-color: #ffbc00;
      color: #000; }

.interstitial__close {
  position: absolute;
  top: -45px;
  right: -61px;
  border: none;
  background-color: transparent;
  display: none; }
  @media screen and (min-width: 768px) {
    .interstitial__close {
      display: block; } }
  .interstitial__close:hover {
    background-color: transparent; }

.interstitial {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: rgba(255, 255, 255, 0.9);
  min-height: 100vh;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: middle;
  width: 100%; }
  .interstitial .interstitial-content-iframe {
    position: relative;
    max-width: 70% !important;
    margin: 0 auto;
    font-size: 1.5rem;
    border: 10px solid transparent;
    background-color: white;
    color: #000;
    text-align: center;
    border-radius: 15px; }
    @media screen and (max-width: 768px) {
      .interstitial .interstitial-content-iframe {
        max-width: 85% !important; } }
  .interstitial .interstitial-content {
    position: relative;
    max-width: 40%;
    margin: 0 auto;
    font-size: 1.5rem;
    border: 10px solid transparent;
    background-color: white;
    color: #000;
    text-align: center;
    border-radius: 15px; }
    @media screen and (max-width: 768px) {
      .interstitial .interstitial-content {
        max-width: 60%; } }
    .interstitial .interstitial-content h2 {
      font-size: 24px;
      line-height: 32px;
      letter-spacing: -0.05px;
      font-weight: 700;
      color: #1c274b; }
    .interstitial .interstitial-content p {
      font-size: 18px;
      line-height: 26px;
      letter-spacing: -0.05px;
      font-weight: 500; }
    .interstitial .interstitial-content .interstitial-close {
      width: 72px;
      height: 72px;
      background-image: url("./images/Close-Default.png");
      background-size: contain;
      position: absolute;
      top: -3rem;
      right: -1.5rem; }
      .interstitial .interstitial-content .interstitial-close:hover {
        background-image: url("./images/Close-Hover.png"); }
    .interstitial .interstitial-content .interstitial-close-iframe {
      top: -23rem !important;
      right: -23rem !important; }
  .interstitial .interstial-links a {
    display: inline-block;
    padding: .5rem 1rem;
    margin: 0 .5rem .5rem 0;
    background: #990000;
    text-decoration: none;
    border-radius: 50px;
    transition: background .5s;
    color: #fff;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 700;
    padding: 12px 66px 12px 67px; }
    .interstitial .interstial-links a:nth-child(2) {
      background: #990000; }
    .interstitial .interstial-links a:hover {
      background: #fff;
      color: white; }

iframe, object, embed {
  max-width: 100%; }
