@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap");
.menu__wrap {
  width: 100%;
  max-width: 1184px;
  margin: auto; }
  @media screen and (min-width: 1178px) {
    .menu__wrap {
      padding: 0 20px;
      max-width: 1135px; } }

header {
  position: fixed;
  width: 100%;
  top: 0px;
  left: 0px;
  z-index: 100;
  background: #000; }
  header .mobile-menu-footLinks {
    display: none; }
  header .social-media-links-mainnav {
    display: none; }
  header.active {
    height: 100vh;
    overflow: scroll;
    position: fixed; }
    header.active .social-media-links-mainnav {
      display: flex;
      padding-left: 2rem; }
      header.active .social-media-links-mainnav .social_media_icon {
        padding-right: 2rem; }
    header.active .mobile-menu-footLinks {
      display: block;
      text-transform: unset;
      border-top: 3px solid #990000;
      width: 80vw;
      padding-top: 1rem; }
      header.active .mobile-menu-footLinks li {
        position: relative;
        left: -.25rem;
        margin: 0.5rem 0;
        align-items: flex-start;
        padding-left: 0rem;
        padding-bottom: 0.5rem; }
        @media screen and (max-width: 1178px) {
          header.active .mobile-menu-footLinks li {
            margin: 1rem 0; } }
        header.active .mobile-menu-footLinks li a {
          text-transform: unset;
          font-family: "Lato", sans-serif;
          letter-spacing: 1.04px;
          text-decoration: none;
          color: white;
          font-size: 1rem; }
    @media screen and (max-width: 1178px) {
      header.active .mobilelogo {
        width: 18rem;
        height: auto;
        position: relative;
        margin: 10rem auto 4rem auto;
        display: none; } }
    header.active .blueLine {
      position: relative;
      height: 0.125rem;
      width: 200%;
      left: -10rem;
      top: 90%;
      background-color: #990000;
      border-radius: 50px;
      margin: 1rem 0rem; }
    header.active .main-menu {
      width: 100%;
      height: 100vh;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: 0px; }
      header.active .main-menu .main-menu_logo_wrapper {
        height: auto; }
      header.active .main-menu .main-menu__links {
        width: 100%;
        height: 80%;
        display: flex;
        margin: 0px; }
        header.active .main-menu .main-menu__links ul {
          flex-direction: column;
          height: 100%;
          align-items: center;
          justify-content: center; }
          header.active .main-menu .main-menu__links ul li {
            width: 100%;
            justify-content: left;
            display: flex;
            align-items: center;
            margin-left: 3rem;
            margin-bottom: 1rem; }
            header.active .main-menu .main-menu__links ul li a {
              text-decoration: none;
              font-weight: 600;
              text-transform: uppercase; }
              header.active .main-menu .main-menu__links ul li a span {
                font-size: 25px;
                color: #fff; }
                header.active .main-menu .main-menu__links ul li a span:active {
                  color: #990000; }
      header.active .main-menu .main-menu__toggle {
        position: absolute;
        top: 60px;
        right: 20px; }
      header.active .main-menu .main-menu__logo {
        top: 3rem;
        margin: 0px;
        width: 200%;
        background: red; }

.localebar {
  display: flex; }
  .localebar .localebar__loc {
    height: 100%;
    display: flex;
    justify-content: flex-start; }
  .localebar .localebar__loc__icon {
    margin: 0 5px;
    width: 1.87rem;
    height: 1.87rem; }
    .localebar .localebar__loc__icon svg {
      color: #fff; }
  .localebar .localebar__loc__select {
    background: transparent;
    color: #fff;
    border: 0px solid #fff;
    border-radius: 0px;
    transition: all 250ms;
    text-align: center;
    text-transform: uppercase; }
    @media screen and (max-width: 768px) {
      .localebar .localebar__loc__select {
        width: 25%; } }
    @media screen and (min-width: 768px) and (max-width: 992px) {
      .localebar .localebar__loc__select {
        width: 12%; } }
    .localebar .localebar__loc__select option {
      border-radius: 0px;
      text-align: center;
      font-family: "Titillium Web", sans-serif;
      color: #fff; }

.main-menu {
  display: flex;
  justify-content: space-between;
  color: #fff;
  align-items: center;
  height: 64px; }
  .main-menu .main-menu__logo {
    z-index: 1; }
  @media screen and (max-width: 1178px) {
    .main-menu {
      padding: 20px;
      margin-top: 0px; } }
  .main-menu.centered {
    justify-content: center;
    margin-left: -1.2rem; }
    .main-menu.centered .main-menu__links {
      margin: 0px; }
    .main-menu.centered .main-menu__logo {
      margin: 0px; }

.main-menu__logo {
  position: relative;
  cursor: pointer;
  width: 100%; }
  .main-menu__logo .mainlogo, .main-menu__logo .mobilelogo {
    transition: all 250ms;
    width: 70%; }
  .main-menu__logo .mobilelogo {
    display: none;
    position: absolute; }
  @media screen and (max-width: 1178px) {
    .main-menu__logo .mainlogo {
      display: block;
      width: 95%; } }
  @media screen and (max-width: 540px) {
    .main-menu__logo .mainlogo {
      width: 75%; }
    .main-menu__logo .mobilelogo {
      transform: translateY(-50%); } }

@media screen and (max-width: 1178px) {
  .main-menu__links {
    display: none; } }

.main-menu__links ul {
  list-style: none;
  padding: 0;
  margin-left: 3.5rem;
  display: flex;
  width: 100%; }
  .main-menu__links ul li {
    float: left;
    display: flex;
    align-items: flex-end; }
    .main-menu__links ul li.active a {
      border-bottom: 5px solid #990000; }
    .main-menu__links ul li.mobile {
      display: none; }
      @media screen and (max-width: 1178px) {
        .main-menu__links ul li.mobile {
          display: block; } }
    .main-menu__links ul li a {
      margin-right: 20px;
      margin-left: 10px;
      padding: 0px;
      border-bottom: 5px solid transparent;
      font-family: "Titillium Web", sans-serif;
      font-size: 1rem;
      font-weight: 700;
      text-transform: uppercase;
      color: #fff;
      text-decoration: none;
      transition: all .3s; }
    .main-menu__links ul li a:hover, .main-menu__links ul li a:visited {
      border-bottom: 5px solid #990000; }
  @media screen and (max-width: 768px) {
    .main-menu__links ul {
      margin-left: 2.5rem; } }
  @media screen and (max-width: 540px) {
    .main-menu__links ul {
      margin-left: 0.25rem; } }

.main-menu__toggle {
  cursor: pointer;
  color: #001e53;
  display: none;
  font-size: 2.8em; }
  .main-menu__toggle .bars {
    display: block;
    color: #fff; }
    .main-menu__toggle .bars.active {
      display: none; }
  .main-menu__toggle .exit {
    display: none; }
    .main-menu__toggle .exit.active {
      display: block;
      color: #fff;
      font-size: 3rem; }
  @media screen and (max-width: 1178px) {
    .main-menu__toggle {
      display: block; } }

.socialmedia-img-mobile {
  position: relative;
  left: -9rem;
  bottom: 1rem; }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    .socialmedia-img-mobile {
      left: -20rem; } }
