@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap");
.flex-container {
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0 1em; }
  @media (min-width: 540px) {
    .flex-container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .flex-container {
      max-width: 768px; } }
  @media (min-width: 992px) {
    .flex-container {
      max-width: 992px; } }
  @media (min-width: 1200px) {
    .flex-container {
      max-width: 1200px; } }

.flex-row {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1em; }
  .flex-row.flex-direction-column {
    flex-direction: column; }
  @media screen and (max-width: 992px) {
    .flex-row.flex-direction-column-mobile-med {
      flex-direction: column; } }

.flex-row--spacebetween {
  justify-content: space-between; }

.flex-row--align-v-column {
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap; }

.flex-row--align-v-center {
  align-items: center; }

.flex-row--align-v-top {
  align-items: flex-start; }

.flex-row--align-v-bottom {
  align-items: flex-end; }

.flex-row--align-h-center {
  justify-content: center; }

.flex-row--align-h-right {
  justify-content: flex-end; }

.flex-row--reverse {
  flex-direction: row-reverse; }

.flex-col-xs-1,
.flex-col-sm-1,
.flex-col-md-1,
.flex-col-lg-1 {
  box-sizing: border-box;
  padding: 1em;
  width: 100%; }
  .flex-col-xs-1.mod-centered,
  .flex-col-sm-1.mod-centered,
  .flex-col-md-1.mod-centered,
  .flex-col-lg-1.mod-centered {
    justify-content: center;
    display: flex; }
  .flex-col-xs-1.mod-flex-end,
  .flex-col-sm-1.mod-flex-end,
  .flex-col-md-1.mod-flex-end,
  .flex-col-lg-1.mod-flex-end {
    display: flex;
    justify-content: flex-end; }
  .flex-col-xs-1.mod-end,
  .flex-col-sm-1.mod-end,
  .flex-col-md-1.mod-end,
  .flex-col-lg-1.mod-end {
    display: flex;
    justify-content: end; }
  .flex-col-xs-1.mod-bgimage,
  .flex-col-sm-1.mod-bgimage,
  .flex-col-md-1.mod-bgimage,
  .flex-col-lg-1.mod-bgimage {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; }
  .flex-col-xs-1.align-center,
  .flex-col-sm-1.align-center,
  .flex-col-md-1.align-center,
  .flex-col-lg-1.align-center {
    display: flex;
    align-items: center; }
  .flex-col-xs-1.flex-direction-column,
  .flex-col-sm-1.flex-direction-column,
  .flex-col-md-1.flex-direction-column,
  .flex-col-lg-1.flex-direction-column {
    flex-direction: column; }
  .flex-col-xs-1.no-padding,
  .flex-col-sm-1.no-padding,
  .flex-col-md-1.no-padding,
  .flex-col-lg-1.no-padding {
    padding: 0; }
  @media screen and (max-width: 992px) {
    .flex-col-xs-1.unset-width-mobile-med,
    .flex-col-sm-1.unset-width-mobile-med,
    .flex-col-md-1.unset-width-mobile-med,
    .flex-col-lg-1.unset-width-mobile-med {
      width: unset; } }

@media (min-width: 540px) {
  .flex-col-xs-1 {
    width: calc(100% * (1 / 12)); } }

@media (min-width: 768px) {
  .flex-col-sm-1 {
    width: calc(100% * (1 / 12)); } }

@media (min-width: 992px) {
  .flex-col-md-1 {
    width: calc(100% * (1 / 12)); } }

@media (min-width: 1200px) {
  .flex-col-lg-1 {
    width: calc(100% * (1 / 12)); } }

.flex-col-xs-2,
.flex-col-sm-2,
.flex-col-md-2,
.flex-col-lg-2 {
  box-sizing: border-box;
  padding: 1em;
  width: 100%; }
  .flex-col-xs-2.mod-centered,
  .flex-col-sm-2.mod-centered,
  .flex-col-md-2.mod-centered,
  .flex-col-lg-2.mod-centered {
    justify-content: center;
    display: flex; }
  .flex-col-xs-2.mod-flex-end,
  .flex-col-sm-2.mod-flex-end,
  .flex-col-md-2.mod-flex-end,
  .flex-col-lg-2.mod-flex-end {
    display: flex;
    justify-content: flex-end; }
  .flex-col-xs-2.mod-end,
  .flex-col-sm-2.mod-end,
  .flex-col-md-2.mod-end,
  .flex-col-lg-2.mod-end {
    display: flex;
    justify-content: end; }
  .flex-col-xs-2.mod-bgimage,
  .flex-col-sm-2.mod-bgimage,
  .flex-col-md-2.mod-bgimage,
  .flex-col-lg-2.mod-bgimage {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; }
  .flex-col-xs-2.align-center,
  .flex-col-sm-2.align-center,
  .flex-col-md-2.align-center,
  .flex-col-lg-2.align-center {
    display: flex;
    align-items: center; }
  .flex-col-xs-2.flex-direction-column,
  .flex-col-sm-2.flex-direction-column,
  .flex-col-md-2.flex-direction-column,
  .flex-col-lg-2.flex-direction-column {
    flex-direction: column; }
  .flex-col-xs-2.no-padding,
  .flex-col-sm-2.no-padding,
  .flex-col-md-2.no-padding,
  .flex-col-lg-2.no-padding {
    padding: 0; }
  @media screen and (max-width: 992px) {
    .flex-col-xs-2.unset-width-mobile-med,
    .flex-col-sm-2.unset-width-mobile-med,
    .flex-col-md-2.unset-width-mobile-med,
    .flex-col-lg-2.unset-width-mobile-med {
      width: unset; } }

@media (min-width: 540px) {
  .flex-col-xs-2 {
    width: calc(100% * (2 / 12)); } }

@media (min-width: 768px) {
  .flex-col-sm-2 {
    width: calc(100% * (2 / 12)); } }

@media (min-width: 992px) {
  .flex-col-md-2 {
    width: calc(100% * (2 / 12)); } }

@media (min-width: 1200px) {
  .flex-col-lg-2 {
    width: calc(100% * (2 / 12)); } }

.flex-col-xs-3,
.flex-col-sm-3,
.flex-col-md-3,
.flex-col-lg-3 {
  box-sizing: border-box;
  padding: 1em;
  width: 100%; }
  .flex-col-xs-3.mod-centered,
  .flex-col-sm-3.mod-centered,
  .flex-col-md-3.mod-centered,
  .flex-col-lg-3.mod-centered {
    justify-content: center;
    display: flex; }
  .flex-col-xs-3.mod-flex-end,
  .flex-col-sm-3.mod-flex-end,
  .flex-col-md-3.mod-flex-end,
  .flex-col-lg-3.mod-flex-end {
    display: flex;
    justify-content: flex-end; }
  .flex-col-xs-3.mod-end,
  .flex-col-sm-3.mod-end,
  .flex-col-md-3.mod-end,
  .flex-col-lg-3.mod-end {
    display: flex;
    justify-content: end; }
  .flex-col-xs-3.mod-bgimage,
  .flex-col-sm-3.mod-bgimage,
  .flex-col-md-3.mod-bgimage,
  .flex-col-lg-3.mod-bgimage {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; }
  .flex-col-xs-3.align-center,
  .flex-col-sm-3.align-center,
  .flex-col-md-3.align-center,
  .flex-col-lg-3.align-center {
    display: flex;
    align-items: center; }
  .flex-col-xs-3.flex-direction-column,
  .flex-col-sm-3.flex-direction-column,
  .flex-col-md-3.flex-direction-column,
  .flex-col-lg-3.flex-direction-column {
    flex-direction: column; }
  .flex-col-xs-3.no-padding,
  .flex-col-sm-3.no-padding,
  .flex-col-md-3.no-padding,
  .flex-col-lg-3.no-padding {
    padding: 0; }
  @media screen and (max-width: 992px) {
    .flex-col-xs-3.unset-width-mobile-med,
    .flex-col-sm-3.unset-width-mobile-med,
    .flex-col-md-3.unset-width-mobile-med,
    .flex-col-lg-3.unset-width-mobile-med {
      width: unset; } }

@media (min-width: 540px) {
  .flex-col-xs-3 {
    width: calc(100% * (3 / 12)); } }

@media (min-width: 768px) {
  .flex-col-sm-3 {
    width: calc(100% * (3 / 12)); } }

@media (min-width: 992px) {
  .flex-col-md-3 {
    width: calc(100% * (3 / 12)); } }

@media (min-width: 1200px) {
  .flex-col-lg-3 {
    width: calc(100% * (3 / 12)); } }

.flex-col-xs-4,
.flex-col-sm-4,
.flex-col-md-4,
.flex-col-lg-4 {
  box-sizing: border-box;
  padding: 1em;
  width: 100%; }
  .flex-col-xs-4.mod-centered,
  .flex-col-sm-4.mod-centered,
  .flex-col-md-4.mod-centered,
  .flex-col-lg-4.mod-centered {
    justify-content: center;
    display: flex; }
  .flex-col-xs-4.mod-flex-end,
  .flex-col-sm-4.mod-flex-end,
  .flex-col-md-4.mod-flex-end,
  .flex-col-lg-4.mod-flex-end {
    display: flex;
    justify-content: flex-end; }
  .flex-col-xs-4.mod-end,
  .flex-col-sm-4.mod-end,
  .flex-col-md-4.mod-end,
  .flex-col-lg-4.mod-end {
    display: flex;
    justify-content: end; }
  .flex-col-xs-4.mod-bgimage,
  .flex-col-sm-4.mod-bgimage,
  .flex-col-md-4.mod-bgimage,
  .flex-col-lg-4.mod-bgimage {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; }
  .flex-col-xs-4.align-center,
  .flex-col-sm-4.align-center,
  .flex-col-md-4.align-center,
  .flex-col-lg-4.align-center {
    display: flex;
    align-items: center; }
  .flex-col-xs-4.flex-direction-column,
  .flex-col-sm-4.flex-direction-column,
  .flex-col-md-4.flex-direction-column,
  .flex-col-lg-4.flex-direction-column {
    flex-direction: column; }
  .flex-col-xs-4.no-padding,
  .flex-col-sm-4.no-padding,
  .flex-col-md-4.no-padding,
  .flex-col-lg-4.no-padding {
    padding: 0; }
  @media screen and (max-width: 992px) {
    .flex-col-xs-4.unset-width-mobile-med,
    .flex-col-sm-4.unset-width-mobile-med,
    .flex-col-md-4.unset-width-mobile-med,
    .flex-col-lg-4.unset-width-mobile-med {
      width: unset; } }

@media (min-width: 540px) {
  .flex-col-xs-4 {
    width: calc(100% * (4 / 12)); } }

@media (min-width: 768px) {
  .flex-col-sm-4 {
    width: calc(100% * (4 / 12)); } }

@media (min-width: 992px) {
  .flex-col-md-4 {
    width: calc(100% * (4 / 12)); } }

@media (min-width: 1200px) {
  .flex-col-lg-4 {
    width: calc(100% * (4 / 12)); } }

.flex-col-xs-5,
.flex-col-sm-5,
.flex-col-md-5,
.flex-col-lg-5 {
  box-sizing: border-box;
  padding: 1em;
  width: 100%; }
  .flex-col-xs-5.mod-centered,
  .flex-col-sm-5.mod-centered,
  .flex-col-md-5.mod-centered,
  .flex-col-lg-5.mod-centered {
    justify-content: center;
    display: flex; }
  .flex-col-xs-5.mod-flex-end,
  .flex-col-sm-5.mod-flex-end,
  .flex-col-md-5.mod-flex-end,
  .flex-col-lg-5.mod-flex-end {
    display: flex;
    justify-content: flex-end; }
  .flex-col-xs-5.mod-end,
  .flex-col-sm-5.mod-end,
  .flex-col-md-5.mod-end,
  .flex-col-lg-5.mod-end {
    display: flex;
    justify-content: end; }
  .flex-col-xs-5.mod-bgimage,
  .flex-col-sm-5.mod-bgimage,
  .flex-col-md-5.mod-bgimage,
  .flex-col-lg-5.mod-bgimage {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; }
  .flex-col-xs-5.align-center,
  .flex-col-sm-5.align-center,
  .flex-col-md-5.align-center,
  .flex-col-lg-5.align-center {
    display: flex;
    align-items: center; }
  .flex-col-xs-5.flex-direction-column,
  .flex-col-sm-5.flex-direction-column,
  .flex-col-md-5.flex-direction-column,
  .flex-col-lg-5.flex-direction-column {
    flex-direction: column; }
  .flex-col-xs-5.no-padding,
  .flex-col-sm-5.no-padding,
  .flex-col-md-5.no-padding,
  .flex-col-lg-5.no-padding {
    padding: 0; }
  @media screen and (max-width: 992px) {
    .flex-col-xs-5.unset-width-mobile-med,
    .flex-col-sm-5.unset-width-mobile-med,
    .flex-col-md-5.unset-width-mobile-med,
    .flex-col-lg-5.unset-width-mobile-med {
      width: unset; } }

@media (min-width: 540px) {
  .flex-col-xs-5 {
    width: calc(100% * (5 / 12)); } }

@media (min-width: 768px) {
  .flex-col-sm-5 {
    width: calc(100% * (5 / 12)); } }

@media (min-width: 992px) {
  .flex-col-md-5 {
    width: calc(100% * (5 / 12)); } }

@media (min-width: 1200px) {
  .flex-col-lg-5 {
    width: calc(100% * (5 / 12)); } }

.flex-col-xs-6,
.flex-col-sm-6,
.flex-col-md-6,
.flex-col-lg-6 {
  box-sizing: border-box;
  padding: 1em;
  width: 100%; }
  .flex-col-xs-6.mod-centered,
  .flex-col-sm-6.mod-centered,
  .flex-col-md-6.mod-centered,
  .flex-col-lg-6.mod-centered {
    justify-content: center;
    display: flex; }
  .flex-col-xs-6.mod-flex-end,
  .flex-col-sm-6.mod-flex-end,
  .flex-col-md-6.mod-flex-end,
  .flex-col-lg-6.mod-flex-end {
    display: flex;
    justify-content: flex-end; }
  .flex-col-xs-6.mod-end,
  .flex-col-sm-6.mod-end,
  .flex-col-md-6.mod-end,
  .flex-col-lg-6.mod-end {
    display: flex;
    justify-content: end; }
  .flex-col-xs-6.mod-bgimage,
  .flex-col-sm-6.mod-bgimage,
  .flex-col-md-6.mod-bgimage,
  .flex-col-lg-6.mod-bgimage {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; }
  .flex-col-xs-6.align-center,
  .flex-col-sm-6.align-center,
  .flex-col-md-6.align-center,
  .flex-col-lg-6.align-center {
    display: flex;
    align-items: center; }
  .flex-col-xs-6.flex-direction-column,
  .flex-col-sm-6.flex-direction-column,
  .flex-col-md-6.flex-direction-column,
  .flex-col-lg-6.flex-direction-column {
    flex-direction: column; }
  .flex-col-xs-6.no-padding,
  .flex-col-sm-6.no-padding,
  .flex-col-md-6.no-padding,
  .flex-col-lg-6.no-padding {
    padding: 0; }
  @media screen and (max-width: 992px) {
    .flex-col-xs-6.unset-width-mobile-med,
    .flex-col-sm-6.unset-width-mobile-med,
    .flex-col-md-6.unset-width-mobile-med,
    .flex-col-lg-6.unset-width-mobile-med {
      width: unset; } }

@media (min-width: 540px) {
  .flex-col-xs-6 {
    width: calc(100% * (6 / 12)); } }

@media (min-width: 768px) {
  .flex-col-sm-6 {
    width: calc(100% * (6 / 12)); } }

@media (min-width: 992px) {
  .flex-col-md-6 {
    width: calc(100% * (6 / 12)); } }

@media (min-width: 1200px) {
  .flex-col-lg-6 {
    width: calc(100% * (6 / 12)); } }

.flex-col-xs-7,
.flex-col-sm-7,
.flex-col-md-7,
.flex-col-lg-7 {
  box-sizing: border-box;
  padding: 1em;
  width: 100%; }
  .flex-col-xs-7.mod-centered,
  .flex-col-sm-7.mod-centered,
  .flex-col-md-7.mod-centered,
  .flex-col-lg-7.mod-centered {
    justify-content: center;
    display: flex; }
  .flex-col-xs-7.mod-flex-end,
  .flex-col-sm-7.mod-flex-end,
  .flex-col-md-7.mod-flex-end,
  .flex-col-lg-7.mod-flex-end {
    display: flex;
    justify-content: flex-end; }
  .flex-col-xs-7.mod-end,
  .flex-col-sm-7.mod-end,
  .flex-col-md-7.mod-end,
  .flex-col-lg-7.mod-end {
    display: flex;
    justify-content: end; }
  .flex-col-xs-7.mod-bgimage,
  .flex-col-sm-7.mod-bgimage,
  .flex-col-md-7.mod-bgimage,
  .flex-col-lg-7.mod-bgimage {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; }
  .flex-col-xs-7.align-center,
  .flex-col-sm-7.align-center,
  .flex-col-md-7.align-center,
  .flex-col-lg-7.align-center {
    display: flex;
    align-items: center; }
  .flex-col-xs-7.flex-direction-column,
  .flex-col-sm-7.flex-direction-column,
  .flex-col-md-7.flex-direction-column,
  .flex-col-lg-7.flex-direction-column {
    flex-direction: column; }
  .flex-col-xs-7.no-padding,
  .flex-col-sm-7.no-padding,
  .flex-col-md-7.no-padding,
  .flex-col-lg-7.no-padding {
    padding: 0; }
  @media screen and (max-width: 992px) {
    .flex-col-xs-7.unset-width-mobile-med,
    .flex-col-sm-7.unset-width-mobile-med,
    .flex-col-md-7.unset-width-mobile-med,
    .flex-col-lg-7.unset-width-mobile-med {
      width: unset; } }

@media (min-width: 540px) {
  .flex-col-xs-7 {
    width: calc(100% * (7 / 12)); } }

@media (min-width: 768px) {
  .flex-col-sm-7 {
    width: calc(100% * (7 / 12)); } }

@media (min-width: 992px) {
  .flex-col-md-7 {
    width: calc(100% * (7 / 12)); } }

@media (min-width: 1200px) {
  .flex-col-lg-7 {
    width: calc(100% * (7 / 12)); } }

.flex-col-xs-8,
.flex-col-sm-8,
.flex-col-md-8,
.flex-col-lg-8 {
  box-sizing: border-box;
  padding: 1em;
  width: 100%; }
  .flex-col-xs-8.mod-centered,
  .flex-col-sm-8.mod-centered,
  .flex-col-md-8.mod-centered,
  .flex-col-lg-8.mod-centered {
    justify-content: center;
    display: flex; }
  .flex-col-xs-8.mod-flex-end,
  .flex-col-sm-8.mod-flex-end,
  .flex-col-md-8.mod-flex-end,
  .flex-col-lg-8.mod-flex-end {
    display: flex;
    justify-content: flex-end; }
  .flex-col-xs-8.mod-end,
  .flex-col-sm-8.mod-end,
  .flex-col-md-8.mod-end,
  .flex-col-lg-8.mod-end {
    display: flex;
    justify-content: end; }
  .flex-col-xs-8.mod-bgimage,
  .flex-col-sm-8.mod-bgimage,
  .flex-col-md-8.mod-bgimage,
  .flex-col-lg-8.mod-bgimage {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; }
  .flex-col-xs-8.align-center,
  .flex-col-sm-8.align-center,
  .flex-col-md-8.align-center,
  .flex-col-lg-8.align-center {
    display: flex;
    align-items: center; }
  .flex-col-xs-8.flex-direction-column,
  .flex-col-sm-8.flex-direction-column,
  .flex-col-md-8.flex-direction-column,
  .flex-col-lg-8.flex-direction-column {
    flex-direction: column; }
  .flex-col-xs-8.no-padding,
  .flex-col-sm-8.no-padding,
  .flex-col-md-8.no-padding,
  .flex-col-lg-8.no-padding {
    padding: 0; }
  @media screen and (max-width: 992px) {
    .flex-col-xs-8.unset-width-mobile-med,
    .flex-col-sm-8.unset-width-mobile-med,
    .flex-col-md-8.unset-width-mobile-med,
    .flex-col-lg-8.unset-width-mobile-med {
      width: unset; } }

@media (min-width: 540px) {
  .flex-col-xs-8 {
    width: calc(100% * (8 / 12)); } }

@media (min-width: 768px) {
  .flex-col-sm-8 {
    width: calc(100% * (8 / 12)); } }

@media (min-width: 992px) {
  .flex-col-md-8 {
    width: calc(100% * (8 / 12)); } }

@media (min-width: 1200px) {
  .flex-col-lg-8 {
    width: calc(100% * (8 / 12)); } }

.flex-col-xs-9,
.flex-col-sm-9,
.flex-col-md-9,
.flex-col-lg-9 {
  box-sizing: border-box;
  padding: 1em;
  width: 100%; }
  .flex-col-xs-9.mod-centered,
  .flex-col-sm-9.mod-centered,
  .flex-col-md-9.mod-centered,
  .flex-col-lg-9.mod-centered {
    justify-content: center;
    display: flex; }
  .flex-col-xs-9.mod-flex-end,
  .flex-col-sm-9.mod-flex-end,
  .flex-col-md-9.mod-flex-end,
  .flex-col-lg-9.mod-flex-end {
    display: flex;
    justify-content: flex-end; }
  .flex-col-xs-9.mod-end,
  .flex-col-sm-9.mod-end,
  .flex-col-md-9.mod-end,
  .flex-col-lg-9.mod-end {
    display: flex;
    justify-content: end; }
  .flex-col-xs-9.mod-bgimage,
  .flex-col-sm-9.mod-bgimage,
  .flex-col-md-9.mod-bgimage,
  .flex-col-lg-9.mod-bgimage {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; }
  .flex-col-xs-9.align-center,
  .flex-col-sm-9.align-center,
  .flex-col-md-9.align-center,
  .flex-col-lg-9.align-center {
    display: flex;
    align-items: center; }
  .flex-col-xs-9.flex-direction-column,
  .flex-col-sm-9.flex-direction-column,
  .flex-col-md-9.flex-direction-column,
  .flex-col-lg-9.flex-direction-column {
    flex-direction: column; }
  .flex-col-xs-9.no-padding,
  .flex-col-sm-9.no-padding,
  .flex-col-md-9.no-padding,
  .flex-col-lg-9.no-padding {
    padding: 0; }
  @media screen and (max-width: 992px) {
    .flex-col-xs-9.unset-width-mobile-med,
    .flex-col-sm-9.unset-width-mobile-med,
    .flex-col-md-9.unset-width-mobile-med,
    .flex-col-lg-9.unset-width-mobile-med {
      width: unset; } }

@media (min-width: 540px) {
  .flex-col-xs-9 {
    width: calc(100% * (9 / 12)); } }

@media (min-width: 768px) {
  .flex-col-sm-9 {
    width: calc(100% * (9 / 12)); } }

@media (min-width: 992px) {
  .flex-col-md-9 {
    width: calc(100% * (9 / 12)); } }

@media (min-width: 1200px) {
  .flex-col-lg-9 {
    width: calc(100% * (9 / 12)); } }

.flex-col-xs-10,
.flex-col-sm-10,
.flex-col-md-10,
.flex-col-lg-10 {
  box-sizing: border-box;
  padding: 1em;
  width: 100%; }
  .flex-col-xs-10.mod-centered,
  .flex-col-sm-10.mod-centered,
  .flex-col-md-10.mod-centered,
  .flex-col-lg-10.mod-centered {
    justify-content: center;
    display: flex; }
  .flex-col-xs-10.mod-flex-end,
  .flex-col-sm-10.mod-flex-end,
  .flex-col-md-10.mod-flex-end,
  .flex-col-lg-10.mod-flex-end {
    display: flex;
    justify-content: flex-end; }
  .flex-col-xs-10.mod-end,
  .flex-col-sm-10.mod-end,
  .flex-col-md-10.mod-end,
  .flex-col-lg-10.mod-end {
    display: flex;
    justify-content: end; }
  .flex-col-xs-10.mod-bgimage,
  .flex-col-sm-10.mod-bgimage,
  .flex-col-md-10.mod-bgimage,
  .flex-col-lg-10.mod-bgimage {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; }
  .flex-col-xs-10.align-center,
  .flex-col-sm-10.align-center,
  .flex-col-md-10.align-center,
  .flex-col-lg-10.align-center {
    display: flex;
    align-items: center; }
  .flex-col-xs-10.flex-direction-column,
  .flex-col-sm-10.flex-direction-column,
  .flex-col-md-10.flex-direction-column,
  .flex-col-lg-10.flex-direction-column {
    flex-direction: column; }
  .flex-col-xs-10.no-padding,
  .flex-col-sm-10.no-padding,
  .flex-col-md-10.no-padding,
  .flex-col-lg-10.no-padding {
    padding: 0; }
  @media screen and (max-width: 992px) {
    .flex-col-xs-10.unset-width-mobile-med,
    .flex-col-sm-10.unset-width-mobile-med,
    .flex-col-md-10.unset-width-mobile-med,
    .flex-col-lg-10.unset-width-mobile-med {
      width: unset; } }

@media (min-width: 540px) {
  .flex-col-xs-10 {
    width: calc(100% * (10 / 12)); } }

@media (min-width: 768px) {
  .flex-col-sm-10 {
    width: calc(100% * (10 / 12)); } }

@media (min-width: 992px) {
  .flex-col-md-10 {
    width: calc(100% * (10 / 12)); } }

@media (min-width: 1200px) {
  .flex-col-lg-10 {
    width: calc(100% * (10 / 12)); } }

.flex-col-xs-11,
.flex-col-sm-11,
.flex-col-md-11,
.flex-col-lg-11 {
  box-sizing: border-box;
  padding: 1em;
  width: 100%; }
  .flex-col-xs-11.mod-centered,
  .flex-col-sm-11.mod-centered,
  .flex-col-md-11.mod-centered,
  .flex-col-lg-11.mod-centered {
    justify-content: center;
    display: flex; }
  .flex-col-xs-11.mod-flex-end,
  .flex-col-sm-11.mod-flex-end,
  .flex-col-md-11.mod-flex-end,
  .flex-col-lg-11.mod-flex-end {
    display: flex;
    justify-content: flex-end; }
  .flex-col-xs-11.mod-end,
  .flex-col-sm-11.mod-end,
  .flex-col-md-11.mod-end,
  .flex-col-lg-11.mod-end {
    display: flex;
    justify-content: end; }
  .flex-col-xs-11.mod-bgimage,
  .flex-col-sm-11.mod-bgimage,
  .flex-col-md-11.mod-bgimage,
  .flex-col-lg-11.mod-bgimage {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; }
  .flex-col-xs-11.align-center,
  .flex-col-sm-11.align-center,
  .flex-col-md-11.align-center,
  .flex-col-lg-11.align-center {
    display: flex;
    align-items: center; }
  .flex-col-xs-11.flex-direction-column,
  .flex-col-sm-11.flex-direction-column,
  .flex-col-md-11.flex-direction-column,
  .flex-col-lg-11.flex-direction-column {
    flex-direction: column; }
  .flex-col-xs-11.no-padding,
  .flex-col-sm-11.no-padding,
  .flex-col-md-11.no-padding,
  .flex-col-lg-11.no-padding {
    padding: 0; }
  @media screen and (max-width: 992px) {
    .flex-col-xs-11.unset-width-mobile-med,
    .flex-col-sm-11.unset-width-mobile-med,
    .flex-col-md-11.unset-width-mobile-med,
    .flex-col-lg-11.unset-width-mobile-med {
      width: unset; } }

@media (min-width: 540px) {
  .flex-col-xs-11 {
    width: calc(100% * (11 / 12)); } }

@media (min-width: 768px) {
  .flex-col-sm-11 {
    width: calc(100% * (11 / 12)); } }

@media (min-width: 992px) {
  .flex-col-md-11 {
    width: calc(100% * (11 / 12)); } }

@media (min-width: 1200px) {
  .flex-col-lg-11 {
    width: calc(100% * (11 / 12)); } }

.flex-col-xs-12,
.flex-col-sm-12,
.flex-col-md-12,
.flex-col-lg-12 {
  box-sizing: border-box;
  padding: 1em;
  width: 100%; }
  .flex-col-xs-12.mod-centered,
  .flex-col-sm-12.mod-centered,
  .flex-col-md-12.mod-centered,
  .flex-col-lg-12.mod-centered {
    justify-content: center;
    display: flex; }
  .flex-col-xs-12.mod-flex-end,
  .flex-col-sm-12.mod-flex-end,
  .flex-col-md-12.mod-flex-end,
  .flex-col-lg-12.mod-flex-end {
    display: flex;
    justify-content: flex-end; }
  .flex-col-xs-12.mod-end,
  .flex-col-sm-12.mod-end,
  .flex-col-md-12.mod-end,
  .flex-col-lg-12.mod-end {
    display: flex;
    justify-content: end; }
  .flex-col-xs-12.mod-bgimage,
  .flex-col-sm-12.mod-bgimage,
  .flex-col-md-12.mod-bgimage,
  .flex-col-lg-12.mod-bgimage {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; }
  .flex-col-xs-12.align-center,
  .flex-col-sm-12.align-center,
  .flex-col-md-12.align-center,
  .flex-col-lg-12.align-center {
    display: flex;
    align-items: center; }
  .flex-col-xs-12.flex-direction-column,
  .flex-col-sm-12.flex-direction-column,
  .flex-col-md-12.flex-direction-column,
  .flex-col-lg-12.flex-direction-column {
    flex-direction: column; }
  .flex-col-xs-12.no-padding,
  .flex-col-sm-12.no-padding,
  .flex-col-md-12.no-padding,
  .flex-col-lg-12.no-padding {
    padding: 0; }
  @media screen and (max-width: 992px) {
    .flex-col-xs-12.unset-width-mobile-med,
    .flex-col-sm-12.unset-width-mobile-med,
    .flex-col-md-12.unset-width-mobile-med,
    .flex-col-lg-12.unset-width-mobile-med {
      width: unset; } }

@media (min-width: 540px) {
  .flex-col-xs-12 {
    width: calc(100% * (12 / 12)); } }

@media (min-width: 768px) {
  .flex-col-sm-12 {
    width: calc(100% * (12 / 12)); } }

@media (min-width: 992px) {
  .flex-col-md-12 {
    width: calc(100% * (12 / 12)); } }

@media (min-width: 1200px) {
  .flex-col-lg-12 {
    width: calc(100% * (12 / 12)); } }

html, body {
  overflow-x: hidden; }

body {
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  margin: 0; }

a {
  cursor: pointer; }

ul {
  padding-left: 0px;
  list-style: none; }

button {
  padding: 13px 30px;
  background-color: #ffbc00;
  border: none;
  cursor: pointer;
  border-radius: 50px;
  color: #000;
  text-transform: uppercase;
  font-family: "Titillium Web", sans-serif;
  font-weight: 700;
  transition: all 200ms; }
  button:hover, button:focus {
    background-color: #1c274b;
    color: #fff;
    cursor: pointer; }

.support-button {
  display: inline-block;
  padding: 13px 30px;
  background-color: #001e53;
  border: none;
  cursor: pointer;
  border-radius: 50px;
  color: #fff;
  text-transform: uppercase;
  font-family: "Titillium Web", sans-serif;
  font-weight: 700;
  transition: all 200ms;
  z-index: 999999; }
  .support-button:hover, .support-button:focus {
    background-color: #da291c;
    color: #fff;
    cursor: pointer; }

.banner-spacer {
  margin-top: 40px; }
  @media screen and (max-width: 768px) {
    .banner-spacer {
      margin-top: 0px; } }

.banner {
  height: 17rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }
  .banner img {
    height: 100%;
    width: auto; }

h1, h2, h3 {
  font-family: "Titillium Web", sans-serif; }

h1 {
  font-size: 3em; }

p {
  font-size: 1rem; }

.itl {
  font-style: italic; }

.bold {
  font-weight: 700; }

.h1 {
  font-family: "Titillium Web", sans-serif;
  font-size: 4rem;
  font-weight: 700; }
  .h1.itl {
    font-weight: 300; }

.h2 {
  font-family: "Titillium Web", sans-serif;
  font-size: 2.6rem;
  font-weight: 700; }

.desktop-lookingFor {
  font-family: "Titillium Web", sans-serif;
  font-size: 70px;
  line-height: 75px;
  letter-spacing: -0.29px;
  margin-bottom: 20px; }
  .desktop-lookingFor.white {
    color: white; }
  @media screen and (max-width: 540px) {
    .desktop-lookingFor {
      font-size: 35px;
      line-height: 35px; } }

.desktop-lightItalic {
  font-family: "Titillium Web", sans-serif;
  font-style: italic;
  font-weight: 300;
  font-size: 70px;
  line-height: 75px;
  letter-spacing: -0.29px; }
  .desktop-lightItalic.white {
    color: #fff; }
  @media screen and (max-width: 540px) {
    .desktop-lightItalic {
      font-size: 35px;
      line-height: 35px; } }

.desktop-button {
  font-family: "Titillium Web", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.15px;
  padding: 13px 30px;
  background-color: #ffbc00;
  border: none;
  cursor: pointer;
  border-radius: 50px;
  color: #000;
  text-transform: uppercase;
  transition: all 200ms; }
  .desktop-button:hover, .desktop-button:focus {
    background-color: #1c274b;
    color: #fff;
    cursor: pointer; }
  @media screen and (max-width: 768px) {
    .desktop-button {
      width: 100%;
      max-width: 230px; } }
  .desktop-button.black {
    color: #000; }

.p {
  font-family: "Open Sans", sans-serif;
  font-size: 1.5rem; }

.p_small {
  font-size: 1rem; }

::selection {
  color: #fff;
  background: #990000; }

::-moz-selection {
  color: #fff;
  background: #990000; }

@media screen and (max-width: 768px) {
  .desktoponly {
    display: none; } }

@media screen and (max-width: 1200px) {
  .desktoponly-menu {
    display: none; } }

.mobileonly {
  display: none; }
  @media screen and (max-width: 768px) {
    .mobileonly {
      display: inherit; } }

.home-looking {
  background: url(https://meccano2021.s3.amazonaws.com/Home-Support-Background-Pieces.png) no-repeat;
  background-position: 15%;
  background-size: 61%;
  background-position: 100%; }
