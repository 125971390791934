@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap");
.footer {
  background-color: #000;
  display: flex;
  padding: 3.813rem 1rem 3.928rem 1rem; }
  @media screen and (max-width: 992px) {
    .footer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: unset; } }
  .footer .wrap {
    position: relative;
    display: flex;
    align-items: center; }
    @media screen and (max-width: 992px) {
      .footer .wrap {
        flex-direction: column;
        justify-content: center; } }

.footer__logo {
  max-width: 6.25rem;
  margin-right: 4.5rem; }
  @media screen and (max-width: 992px) {
    .footer__logo {
      margin: 0rem; } }
  .footer__logo img {
    height: 86px;
    width: auto; }

.footer_left {
  display: flex;
  flex-direction: column; }
  @media screen and (max-width: 992px) {
    .footer_left .footer__links {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; } }
  .footer_left .footer__links a {
    margin: 0 2rem 0 0;
    text-decoration: none;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.39px; }
    @media screen and (max-width: 1200px) {
      .footer_left .footer__links a {
        margin: 0.25rem 0rem; } }
    @media screen and (max-width: 992px) {
      .footer_left .footer__links a {
        margin: 0.75rem 0rem; } }

.footer__copyright {
  padding: 1rem 0;
  color: #fff;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.32px; }
  @media screen and (max-width: 992px) {
    .footer__copyright {
      position: relative; } }

.social-media-links {
  position: relative;
  display: grid;
  justify-content: center;
  grid-auto-flow: column;
  gap: 1rem; }
  @media screen and (min-width: 992px) {
    .social-media-links {
      position: absolute;
      right: 0; } }

.social_media_icon {
  height: 2.5rem;
  width: 2.5rem; }
  @media screen and (max-width: 540px) {
    .social_media_icon {
      height: 2.8125rem;
      width: 2.8125rem; } }
